@import "../abstracts/variables";
@import "../abstracts/functions";
@import "../abstracts/keyframes";

.section {
}

.wrapper {
  &__min-height-100 {
    min-height: 100vh;
  }
  &__sosmed {
    display: inline-block;
    padding: 1.5rem 0;
    width: 100%;
    div {
      .wrapSosmed {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        a {
          &:last-child {
            margin-left: 1.5rem;
          }
        }
      }
    }
  }
}

.svg {
  &__responive {
    &.one {
      @media screen and(max-width: 1024px) {
        svg {
          width: 60px;
        }
      }
    }
    &.two {
      @media screen and(max-width: 1024px) {
        svg {
          width: 140px;
        }
      }
    }
  }
}

.images {
  &__bg-head {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
  }
}

.text {
  &__border-yellow {
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 2px color("yellow");
    // color: transparent;
    text-shadow: 0px 0px 24px #ff6500;
  }
}

.btn {
  &__download {
    padding: 17px 45px;
    border-radius: 50px;
    background: transparent;
    border: 5px solid #f8be29;
    box-shadow: 0px 0px 30px #ff6500;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: #ff6500;
      filter: blur(16px);
      z-index: -1;
    }
  }
}

// typography

.text {
  &__163 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 100px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 90px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 65px !important;
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 50px !important;
      }
    }
  }

  &__100 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 80px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 70px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 60px !important;
      }
    }

    &-mm {
      @media screen and(max-width: $br-mm) {
        font-size: 50px !important;
      }
    }

    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 45px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 40px !important;
      }
    }
  }
  &__150 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 120px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 100px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 60px !important;
      }
    }

    &-mm {
      @media screen and(max-width: $br-mm) {
        font-size: 50px !important;
      }
    }

    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 45px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 40px !important;
      }
    }
  }

  &__200 {
    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 150px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 125px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-mm) {
        font-size: 100px !important;
      }
    }

    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 85px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 75px !important;
      }
    }
  }

  &__77 {
    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 50px !important;
      }
    }
  }

  &__80 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 55px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 65px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 50px !important;
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 40px !important;
      }
    }
  }

  &__70 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 60px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 50px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 40px !important;
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 40px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 35px !important;
      }
    }
  }

  &__65 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 45px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 35px !important;
      }
    }
    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 28px !important;
      }
    }
  }

  &__60 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 50px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 35px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 30px !important;
      }
    }
  }

  &__50 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 40px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 35px !important;
      }
    }
    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 25px !important;
      }
    }
  }

  &__25 {
    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 20px !important;
      }
    }
  }

  &__45 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 30px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 24px !important;
      }
    }

    &-mm {
      @media screen and(max-width: $br-mm) {
        font-size: 16px !important;
      }
    }
  }

  &__40 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 30px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 28px !important;
      }
    }

    &-mm {
      @media screen and(max-width: $br-mm) {
        font-size: 24px !important;
      }
    }
  }

  &__30 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 25px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 25px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 25px !important;
      }
    }

    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 20px !important;
      }
    }
  }

  &__32 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 24px !important;
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 18px !important;
      }
    }
    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 16px !important;
      }
    }
  }

  &__36 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 28px !important;
      }
    }
  }

  &__28 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 20px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 20px !important;
      }
    }
  }

  &__35 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 28px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 20px !important;
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 18px !important;
      }
    }
  }

  &__38 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 30px !important;
      }
    }
  }

  &__90 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 70px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 55px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 40px !important;
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 35px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xs) {
        font-size: 30px !important;
      }
    }
  }

  &__33 {
    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 25px !important;
      }
    }

    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 18px !important;
      }
    }
  }

  &__18 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 16px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 16px !important;
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 14px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 12px !important;
      }
    }
  }

  &__16 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 14px !important;
      }

      &-12 {
        @media screen and(max-width: 1024px) {
          font-size: 12px !important;
        }
      }

      &-10 {
        @media screen and(max-width: 1024px) {
          font-size: 10px !important;
        }
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 12px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 12px !important;
      }
    }

    &-mm {
      @media screen and(max-width: $br-mm) {
        font-size: 10px !important;
      }

      &-12 {
        @media screen and(max-width: $br-mm) {
          font-size: 12px !important;
        }
      }
    }

    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 10px !important;
      }
    }
  }

  &__20 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 16px !important;
      }
    }
    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 14px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 12px !important;
      }
    }
  }

  &__120 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 90px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 69px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 75px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 55px !important;
      }
    }
  }

  &__26 {
    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 20px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 14px !important;
      }
    }
  }

  &__22 {
    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 16px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 14px !important;
      }
    }
  }

  &__12 {
    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 10px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 10px !important;
      }

      &-8 {
        @media screen and(max-width: $br-sm) {
          font-size: 8px !important;
        }
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 8px !important;
      }

      &-10 {
        @media screen and(max-width: $br-xs) {
          font-size: 10px !important;
        }
      }
    }

    &-mm {
      @media screen and(max-width: $br-mm) {
        font-size: 8px !important;
      }

      &-10 {
        @media screen and(max-width: $br-mm) {
          font-size: 10px !important;
        }
      }
    }
  }

  &__14 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 12px !important;
      }

      &-10 {
        @media screen and(max-width: 1024px) {
          font-size: 10px !important;
        }
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 12px !important;
      }
    }

    &-sm {
      &-8 {
        @media screen and(max-width: $br-sm) {
          font-size: 8px !important;
        }
      }
    }

    &-mm {
      @media screen and(max-width: $br-mm) {
        font-size: 9px !important;
      }

      &-10 {
        @media screen and(max-width: $br-mm) {
          font-size: 10px !important;
        }
      }
    }

    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 10px !important;
      }

      &-8 {
        @media screen and(max-width: $br-xx) {
          font-size: 8px !important;
        }
      }
    }
  }

  &__27 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 18px !important;
      }
    }
  }

  &__24 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 18px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 16px !important;
      }
    }
    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 14px !important;
      }
    }
  }

  &__25 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 20px !important;
      }
    }
  }

  &__36 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 25px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 20px !important;
      }
    }
  }

  &__58 {
    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 45px !important;
      }
    }
  }

  &__48 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 40px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 35px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 30px !important;
      }
    }
    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 24px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 18px !important;
      }
    }
  }
}

.w {
  &__mm-100 {
    @media screen and(max-width: $br-mm) {
      width: 100%;
    }
  }
}

.wrap {
  &__flex {
    &-1024 {
      &-100 {
        @media screen and(max-width: 1024px) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: 1024px) {
            flex: 0 0 100% !important;
            max-width: 100% !important;
          }
        }
      }

      &-50 {
        @media screen and(max-width: 1024px) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: 1024px) {
            flex: 0 0 45%;
            max-width: 45%;
          }
        }
      }

      &-90 {
        &-3 {
          @media screen and(max-width: 1024px) {
            flex-flow: wrap;
          }
          > .grid {
            @media screen and(max-width: 1024px) {
              flex: 0 0 calc(90% / 3);
              max-width: calc(90% / 3);
            }
          }
        }
      }

      &-auto {
        @media screen and(max-width: 1024px) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: 1024px) {
            flex: 0 0 auto !important;
            max-width: inherit !important;
          }
        }
      }
    }

    &-md {
      &-100 {
        @media screen and(max-width: $br-md) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-md) {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }

      &-50 {
        @media screen and(max-width: $br-md) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-md) {
            flex: 0 0 45%;
            max-width: 45%;
          }
        }
      }

      &-auto {
        @media screen and(max-width: $br-md) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-md) {
            flex: 0 0 auto !important;
            max-width: inherit !important;
          }
        }
      }
    }

    &-sm {
      &-100 {
        @media screen and(max-width: $br-sm) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-sm) {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }

      &-50 {
        @media screen and(max-width: $br-sm) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-sm) {
            flex: 0 0 45%;
            max-width: 45%;
          }
        }
      }
    }

    &-xs {
      &-100 {
        @media screen and(max-width: $br-xs) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-xs) {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }

      &-50 {
        @media screen and(max-width: $br-sm) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-sm) {
            flex: 0 0 45%;
            max-width: 45%;
          }
        }
      }
    }

    &-mm {
      &-100 {
        @media screen and(max-width: $br-mm) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-mm) {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }

      &-50 {
        @media screen and(max-width: $br-sm) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-sm) {
            flex: 0 0 45%;
            max-width: 45%;
          }
        }
      }

      &-4 {
        @media screen and(max-width: $br-mm) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-mm) {
            flex: 0 0 calc(100% / 6);
            max-width: calc(100% / 6);
          }
        }
      }
    }
  }
}

// animated
@keyframes move1 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(13.5rem, 14.5rem);
  }
  75% {
    transform: translate(27rem, 0rem);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes move12 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(9.8rem, 13rem);
  }
  75% {
    transform: translate(19.7rem, 0rem);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes move2 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(-27rem, 0rem);
  }
  75% {
    transform: translate(-13.4rem, 14.5rem);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes move22 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(-19.5rem, 0rem);
  }
  75% {
    transform: translate(-10rem, 13rem);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes move3 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(13.5rem, -14.5rem);
  }
  75% {
    transform: translate(-13.5rem, -14.5rem);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes move32 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(10rem, -13rem);
  }
  75% {
    transform: translate(-10rem, -13rem);
  }
  100% {
    transform: translate(0);
  }
}
