$colors: (
  "white": #ffffff,
  "black": #3D3D3D,
  "yellow": #F8BE29,
  "transparent": transparent,
  "gray-1": #6F6E73,
);

// Default Font Size
$base-font-size: 16px;

// Responsive Breakpoint
$br-xxs: 375px;
$br-xx: 425px;
$br-xs: 480px;
$br-mm: 512px;
$br-sm: 576px;
$br-md: 768px;
$br-lg: 992px;
$br-xl: 1140px;
$br-xxl: 1200px;
